import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";
import {store} from "@/store";
import {updateMe} from "@/account";

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!store.state.me && to.path !== '/login') {
    next(`/login?redirect=${to.path}`)
  } else if (to.path === '/stripe-success') {
    await updateMe()
    next('/')
  } else next();
});

export default router
