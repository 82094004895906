<template>
<div>
  <h2>Dashboard</h2>
  <button class="btn btn-primary" @click="redirectToOnboardingUrl" v-if="!isAccountOnboarded">Daten vervollständigen</button>
</div>
</template>

<script>
import HTTP from "@/axios";
import {store} from "@/store";

export default {
  name: "Dashboard",

  computed: {
    isAccountOnboarded: function () {
      return store.state.me.stripe_payouts_enabled
    }
  },

  methods: {
    redirectToOnboardingUrl: function () {
      HTTP.post('/stripe/account-link').then(res => {
        window.location.href = res.data.onboarding_url
      })
    }
  },

  data() {
    return {

    }
  }
}
</script>

<style scoped>

</style>