import {store} from "@/store";
import HTTP from "@/axios";
import router from "@/router";
import {emitter} from "@/main";

export function login(form: Object) {
    return HTTP.post("/auth/login", form)
        .then((response) => {
            localStorage.setItem('me', JSON.stringify(response.data))
            store.state.me = response.data
            emitter.emit('login')
        })
}

export function isLoggedIn() {
    return localStorage.getItem('me') !== null
}

export function logout() {
    return HTTP.post("/auth/logout").finally(() => {
        store.state.me = undefined
        localStorage.removeItem('me')
        emitter.emit('logout')

        router.push('/login')
    });
}

export async function updateMe() {
    await HTTP.get("/auth/me").then((response) => {
        localStorage.setItem('me', JSON.stringify(response.data))
        store.state.me = response.data
    });
}